@import '~/node_modules/bootstrap-icons/font/bootstrap-icons.min.css'
@import url('https://fonts.googleapis.com/css?family=Roboto')
@import url('https://fonts.googleapis.com/css?family=Roboto+Condensed')

$blue: #2e74b5
$grey: #5b606c
$mediumgrey: #96989f
$lightgrey: #f5f5f4

$coverscale: .7
$coverwidth: calc(150px * $coverscale)

$vizwidth: 200px

html, body
    height: 100%
    width: 100%
    overflow: hidden
    font-family: "Roboto"

html
    visibility: hidden

body
    display: flex
    flex-direction: row
    font-size: 16px
    align-items: center
    padding: 1em 0
    justify-content: center
    height: calc(100% - 2em)

    @media only screen and (max-device-width: 812px) and (orientation: portrait)
        font-size: 2vh
        flex-direction: column
        padding: 0
        height: 100%

hr
    margin-top: .2em

a
    color: $blue
    text-decoration: none

a:hover
    text-decoration: underline

#navigation
    height: 100%
    width: 240px
    display: flex
    flex-direction: column
    align-items: center
    border-right: 1px solid $mediumgrey

    @media only screen and (max-device-width: 812px) and (orientation: portrait)
        width: 100%
        max-width: 100%
        height: auto
        flex-direction: row

    #profile
        display: inline-block
        background: url(img/profile.png) no-repeat center center
        background-size: cover
        width: 170px
        height: 170px
        margin-top: 50px

        @media only screen and (max-device-width: 812px) and (orientation: portrait)
            margin: 1vh 0 1vh 2vh
            width: 100px
            height: 100px

        @media only screen and (max-device-width: 812px) and (orientation: landscape)
            margin: 1vh 0 1vh 2vh
            width: 20vmin
            height: 20vmin

    #menu
        display: inline-block
        margin-top: 30px
        width: 180px

        @media only screen and (max-device-width: 812px) and (orientation: portrait)
            display: flex
            flex-direction: column
            align-items: flex-end
            position: absolute
            top: 0
            right: 0
            z-index: 100

        .menu-button
            display: none

            @media only screen and (max-device-width: 812px) and (orientation: portrait)
                display: block
                text-align: right
                padding: 1vh 3vh 1vh 3vh

            div
                width: 35px
                height: 5px
                background-color: black
                margin: 6px 0

        .menu-item
            width: 100%
            color: $grey
            text-align: center
            font-family: "Roboto Condensed"
            font-size: 1.1em
            cursor: pointer
            padding: .7em 0 .7em 0
            z-index: 100

            @media only screen and (max-device-width: 812px) and (orientation: portrait)
                text-align: right
                background: $lightgrey
                padding: 1.5vh 3vh
                display: none

        .menu-item:hover
            color: $blue
            border-left: 2px solid #2e74b5
            margin-left: -10px
            padding-left: 8px

        .menu-subitem
            font-size: .9em
            font-family: "Roboto"
            padding: .4em 0 .4em 0

            @media only screen and (max-device-width: 812px) and (orientation: portrait)
                padding:  1.5vh 3vh
                z-index: 100

#name-title-mobile
    display: none

    @media only screen and (max-device-width: 812px) and (orientation: portrait)
        display: block
        font-family: "Roboto"
        font-size: 1.3em
        color: $blue
        padding-top: 0.3em
        padding-left: 0.6em

    span
        font-size: 80%
        font-weight: 300

#content::-webkit-scrollbar
    @media only screen and (max-device-width: 812px) and (orientation: portrait)
        display: none

#content
    height: 100%
    width: 860px
    overflow-y: scroll
    position: relative
    padding: 0 2em

    @media only screen and (max-device-width: 812px) and (orientation: portrait)
        padding: 0
        width: calc(100% - 7vw)
        -ms-overflow-style: none
        scrollbar-width: none
        overflow-x: hidden

    #name-title
        margin-top: 60px
        font-family: "Roboto Condensed"
        font-size: 3em
        color: $blue

        @media only screen and (max-device-width: 812px) and (orientation: portrait)
            display: none

        span
            font-size: 80%
            font-weight: 300

    .section
        padding-left: 5px
        font-size: 1em
        line-height: 150%

        .section-header
            border-left: 2px solid $blue
            padding-left: 8px
            padding-top: 1.5em
            margin-bottom: .5em
            margin-top: 2em
            font-family: "Roboto Condensed"
            font-size: 1.3em
            color: $blue

            @media only screen and (max-device-width: 812px) and (orientation: portrait)
                padding-left: 1vh
                margin-top: 1.5em

.grid
    display: table
    border-spacing: 0 .6em

    .grid-row
        display: table-row
        width: 100%
        column-gap: 10px

        .grid-cell
            display: table-cell
            width: auto

        .grid-cell:nth-child(2)
            border-left: 1px solid $blue
            padding-left: 10px

        .grid-cell-year
            display: table-cell
            width: fit-content
            padding-right: 30px

.subsection-header
    font-family: 'Roboto Condensed'
    font-size: 1.2em
    margin-top: .5em
    padding-top: 1em
    color: $blue

.pub-entry
    width: 100%
    margin: .7em 0
    padding-left: 1em
    border-left: 1px solid $blue
    line-height: 130%

    @media only screen and (max-device-width: 812px) and (orientation: portrait)
        width: calc(100% - 6vw)

    .pub-title
        font-weight: 700

    .pub-authors
        font-style: italic

.tile
    position: relative
    z-index: 1
    padding: .1em .3em
    border-radius: .4em
    color: $blue
    line-height: 150%
    background: rgba($blue, .08)
    margin-right: 3px
    white-space: nowrap

.tile:hover
    color: white
    text-decoration: none
    background: rgba($blue, .8)


.spacerv-half-em
    height: .5em

.spacerh-half-em
    width: .5em

.thick
    font-family: "Roboto"
    font-weight: 700

#img-defense
    width: auto
    display: flex
    flex-direction: row
    column-gap: 5px
    margin-top: 5px
    margin-bottom: 5px

#img-defense > div
    background-size: cover
    width: 90px
    height: 90px

#img-defense1
    background: url(img/phd/defense1.jpg) no-repeat center center

#img-defense2
    background: url(img/phd/defense2.jpg) no-repeat center center

#img-defense3
    background: url(img/phd/defense3.jpg) no-repeat center center

#img-defense4
    background: url(img/phd/defense4.jpg) no-repeat center center


.cover-container
    width: 100%
    display: flex
    flex-direction: row
    flex-wrap: wrap
    row-gap: 15px
    margin-top: 15px

    @media only screen and (max-device-width: 812px) and (orientation: portrait)
        column-gap: 1vw

    .cover-tile
        display: flex
        width: 50%

        @media only screen and (max-device-width: 812px) and (orientation: portrait)
            flex-direction: column
            width: calc(50% - 1vw)
            row-gap: 1vh

        .pub-entry
            width: calc(100% - $coverwidth)
            padding: 0 1em
            margin: 0
            border: none
            font-size: 90%

            @media only screen and (max-device-width: 812px) and (orientation: portrait)
                padding: 0
                width: 100%

.cover-img
    background-size: contain !important
    width: $coverwidth
    height: calc(195px * $coverscale)

    @media only screen and (max-device-width: 812px) and (orientation: portrait)
        width: 40vw
        height: calc(40vw / $coverscale)

.viz-tile
    display: flex

    @media only screen and (max-device-width: 812px) and (orientation: portrait)
        flex-direction: column
        row-gap: 3vh
        margin-top: 6vh

.viz-desc
    width: calc(100% - $vizwidth)
    padding: 10px 15px

    @media only screen and (max-device-width: 812px) and (orientation: portrait)
        padding: 0

.viz-img
    background-size: contain !important
    width: $vizwidth
    height: $vizwidth

    @media only screen and (max-device-width: 812px) and (orientation: portrait)
        width: 80vw
        height: 80vw

#cover-fab
    background: url(img/covers/cover_fab.png) no-repeat center center

#cover-esr
    background: url(img/covers/cover_esr.png) no-repeat center center

#cover-whateconcan
    background: url(img/covers/cover_whateconcan.png) no-repeat center center

#cover-demystifying
    background: url(img/covers/cover_demystifying-small.png) no-repeat center center

#cover-cwon2021
    background: url(img/covers/cover_cwon2021-small.png) no-repeat center center

#cover-newdawn
    background: url(img/covers/cover_new-dawn-small.png) no-repeat center center

#cover-creditworthy
    background: url(img/covers/cover_credit-worthy-small.png) no-repeat center center

#cover-stochasticfamine
    background: url(img/covers/cover_stochasticfamine.png) no-repeat center center

#cover-predictingfoodcrises
    background: url(img/covers/cover_predictingfoodcrises.png) no-repeat center center

#cover-transitionsfamine
    background: url(img/covers/cover_transitionsfamine.jpg) no-repeat center center

#cover-slbblog1
    background: url(img/covers/cover_slbblog1.png) no-repeat center center

#cover-slbblog2
    background: url(img/covers/cover_slbblog2.png) no-repeat center center

#viz-biodiversity
    background: url(img/viz/biodiversity.png) no-repeat center center

#viz-ripplingnetwork
    background: url(img/viz/ripplingnetwork.png) no-repeat center center

#viz-bisintlbanking
    background: url(img/viz/bisintlbanking.png) no-repeat center center

#viz-contagionchain
    background: url(img/viz/contagionchain.png) no-repeat center center

#img-linkedin
    background: url(img/linkedin.svg) no-repeat center center
    height: 1em
    background-size: contain

.contact
    margin-bottom: 4em

.box
    background: $lightgrey
    padding: 18px

    .thick
        color: $blue

#newsboxes
    display: flex
    flex-direction: column
    row-gap: 5px
    margin-top: 15px
    background: #e0ecff
    padding: 1em

    h1
        font-variant: all-small-caps
        font-size: 22px
        letter-spacing: .1em
        margin: 0 0 .3em 0
        color: $blue

        @media only screen and (max-device-width: 812px) and (orientation: portrait)
            font-size: 2.5vh
            margin: 0

    @media only screen and (max-device-width: 812px) and (orientation: portrait)
        row-gap: 1.3vh
        margin-top: 2vh

    .newsbox
        margin-bottom: .5em

    .newsbox:last-of-type
        margin-bottom: 0

    .newscontent
        display: flex
        flex-direction: row
        column-gap: 0.5em
        border-left: 2px solid $blue
        padding-left: 0.6em

        div
            display: flex
            flex-direction: column
            flex-wrap: wrap
            row-gap: .2em
            align-items: flex-start



// SCROLLBAR
// ===== Scrollbar CSS =====
// Firefox
.skinnyscrollbar
    overflow-y: auto
    scrollbar-width: auto
    scrollbar-color: rgba($mediumgrey, .7) rgba($mediumgrey, .1)


// Chrome, Edge, and Safari
.skinnyscrollbar::-webkit-scrollbar
    width: 6px


.skinnyscrollbar::-webkit-scrollbar-track
    background: rgba($mediumgrey, .1)


.skinnyscrollbar::-webkit-scrollbar-thumb
    background-color: rgba($mediumgrey, .7)
    border-radius: 6px
    border: 0px none #ffffff
